<template>
  <div>
    
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <notifications></notifications>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
  
    <div class="container mt--8  pb-5 mt-movil">
      <div class="row justify-content-center ">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Restablecer contraseña</small>
              </div>
              <loading-overlay :active.sync="loading" :is-full-page="true" />
              <form class="needs-validation" @submit.prevent="handleSubmit()">
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="fa fa-unlock-alt"
                  placeholder="Password"
                  type="password"
                  name="Password"
                  v-model="form.data.attributes.password"
                  rules="required"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="fa fa-unlock-alt"
                  placeholder="Confirm Password"
                  type="password"
                  name="Password confirmation"
                  v-model="form.data.attributes.password_confirmation"
                  rules="required"
                >
                </base-input>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Restableceer contraseña</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import router from "@/router";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";


export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      form: {
        data: {
          type: "password-reset",
          attributes: {
            password: "",
            password_confirmation: "",
            token: "",
            email: "",
          },
        },
      },
      loading:false,
    };
  },
  mounted() {
    this.form.data.attributes.email = this.$route.query.email;
    this.form.data.attributes.token = this.$route.query.token;
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    /* async handleSubmit() {
      try {
        await this.$store.dispatch("reset/createNewPassword", this.form.data);
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: "The given data was invalid.",
        });
        this.setApiValidation(error.response.data.errors);
      }
    }, */
    handleSubmit() {
      swal.fire({
          title: "¿Está seguro de restablecer su contraseña?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.recoverPassword();
          } else {
            this.password = "";
            this.password_confirmation = "";
          }
        });
    },

    async recoverPassword() {
      this.loading = true;
      if (this.form.data.attributes.password.length < 8) {
        this.$notify({
          type: "danger",
          message: "La contraseña debe tener por lo menos 8 caracteres.",
        });
        this.loading = false;
        return;
      }

      if (this.form.data.attributes.password != this.form.data.attributes.password_confirmation) {
        this.$notify({
          type: "danger",
          message: "Las contraseñas no coinciden.",
        });
        this.loading = false;
        return;
      }
      try {
        await this.$store.dispatch("reset/createNewPassword", this.form.data);
        this.loading = false;
        this.$notify({
          message: "Contraseña restablecida exitosamente.",
        });
        router.push({ path: "/login",});
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$notify({
          type: "danger",
          message: 'El enlace ingresado ha expirado, solicite uno nuevo en "recuperar contraseña".',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
   
  },
};
</script>
<style scoped>

/* Escritorio pequeño o tablet vertical */
@media (max-width: 991px) and (min-width: 500px) {
  .mt-movil {
    margin-top: -3rem !important;
  }
}
  /* Movil */
@media (max-width: 499px) {
  .mt-movil {
    margin-top: -3rem !important;
  }
}
</style>
